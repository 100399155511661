<template>
  <div class="film-invalid position-relative">
    <b-overlay :show="filmBusy">
      <pagination :meta="invalidsMeta" :implement-search="false" @change-page="onChangePage" @refresh="onRefresh" />
      <b-row>
        <b-col v-for="invalid in invalids" :key="`region_${invalid.id}`" md="3" sm="6">
          <portlet :title="` `" body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm">
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__head">
                    <div class="kt-widget__media">
                      <b-avatar rounded size="55px" text="!" :src="invalid.episode && invalid.episode.film ? invalid.episode.film.preview : ''" />
                    </div>
                    <div class="kt-widget__info">
                      <a class="kt-widget__username">
                        {{ invalid.episode && invalid.episode.film ? invalid.episode.film.title : 'نامەلۇم' }}
                      </a>
                    </div>
                  </div>
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">سەۋەبى: </span>
                        <span class="kt-widget__data" v-if="invalid.is_delete">سالوندىن ئۆچۈرۈلگەن</span>
                        <span class="kt-widget__data" v-else-if="invalid.is_forbid">سالوندىن چۈشۈرىۋېتىلگەن</span>
                      </div>
                      <div class="kt-widget__contact" v-if="invalid.notes">
                        <span class="kt-widget__label">مەسىلە چىققان قىسىم: </span>
                        <span class="kt-widget__data">{{ invalid.notes || '' }}</span>
                      </div>
                      <div class="kt-widget__contact" v-else>
                        <span class="kt-widget__label">⌘</span>
                        <span class="kt-widget__data">&nbsp;</span>
                      </div>
                    </div>
                  </div>
                  <div class="kt-widget__footer">
                    <b-button @click="onClickEdit(invalid)" variant="primary">تەھىرىرلەش</b-button>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!invalids.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، ھازىرچە فىلىملارنىڭ ھەممىسى نورمال ئىكەن." />
    </b-overlay>
    <invalid-edit-modal v-model="invalidState" :info="invalidInfo" @success="onSuccess" />
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NoResult from "@v@/partials/content/NoResult";
  import invalidEditModal from "@v@/components/modals/film.invalid.edit.modal";
  import filmMixin from "@m@/film.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import sweetAlert from "@m@/common/sweetAlert.mixin";
  import pagination from "@v@/components/paginate";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import {SET_FILM_ERROR} from "@/store/modules/film.module";
  import { mapGetters } from "vuex";

  export default {
    name: "index",
    components: { Portlet, pagination, NoResult, invalidEditModal },
    mixins: [filmMixin, formBusyMixin],
    data(){
      return {
        filmBusy: false,
        page: 1,
        title: '',
        invalidState: false,
        invalidInfo: {}
      };
    },
    computed: {
      ...mapGetters(['invalids', 'invalidsMeta']),
    },
    created() {
      this.getInvalidFilms();
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'فىلىم باشقۇرۇش', route: {name: 'film.home'}});
      BreadCrumbs.addBreadCrumb({title: 'بارلىق چىقمايدىغان فىلىملەر'});
    },
    watch: {},
    methods: {
      autoRefresh(rootPage = null, rootTitle = null){
        if ( rootPage ){
          this.page = rootPage;
        }
        if (rootTitle){
          this.title = rootTitle;
        }
        let { filmPage, title } = this;
        this.getInvalidFilms(rootPage ? rootPage : filmPage, rootTitle ? rootTitle : title);
      },
      onChangePage(e){
        let { title, page } = e;
        this.autoRefresh(page, title);
      },
      onRefresh(title){
        this.autoRefresh(1, title);
      },
      onChangeSwitch(id, column, value){
        this.switchColumn({
          resource: 'films',
          id, column, mutationType: SET_FILM_ERROR,
          begin: ()=>{ this.showBusy('filmBusy') },
          after: ()=>{ this.hideBusy('filmBusy') },
        });
      },
      toggleInvalidModal(){
        this.invalidState = ! this.invalidState
      },
      onClickEdit(invalid){
        this.invalidInfo = invalid;
        this.toggleInvalidModal();
      },
      onSuccess(data){
        let { id } = data.data;
        this.processedInvalidFilm(id)
          .then(()=>{
            this.toggleInvalidModal();
            this.autoRefresh();
          }).catch(()=>{
            this.warning({ message: 'كۈتۈلمىگەن خاتالىق كۆرۈلدى. سەل تۇرۇپ قايتا سىناڭ' })
          })
      },
    }
  }
</script>

<style scoped>
  .btn i {
    padding-left: 0;
  }

  .kt-widget__media .b-avatar {
    width: 100% !important;
  }
</style>
