<template>
  <div class="invalid-edit-modal">
    <base-modal :id="id" :title="title" :modal-busy="invalidBusy" :size="size" v-model="modalState" bg="bg-grey">
      <b-container fluid="" class="p-2">
        <b-row class="justify-content-center">
          <b-col sm="12" md="12">
            <episode-form single-mode action="edit" hide-other :model-id="info.episode_id" ref="episode" @updating="handleUpdating" @updated="handleUpdated" />
          </b-col>
        </b-row>
        <b-button @click="onConfirm" variant="primary">
          ساقلاش
        </b-button>
      </b-container>
    </base-modal>
  </div>
</template>

<script>
  import baseModal from "@v@/components/modals/base.modal";
  import EpisodeForm from "@v@/components/forms/EpisodeForm";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import sweetAlert from "@m@/common/sweetAlert.mixin";
  import {mapGetters} from "vuex";
  export default {
    name: "FilmInvalidEditModal",
    props: {
      id: {
        type: String,
        default: () => 'film-invalid-edit-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String,
        default: () => 'قويۇش ئۇچۇرى تەھىرىرلەش'
      },
      size: {
        type: String,
        default: () => 'xl'
      },
      info: {
        type: Object,
        default: ()=>{ return {} }
      }
    },
    components: { baseModal, EpisodeForm },
    mixins: [ formBusyMixin ],
    computed: {
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
      ...mapGetters([])
    },
    watch: {},
    data(){
      return {
        invalidBusy: false,
      };
    },
    methods: {
      onConfirm(){
        this.$refs['episode'].onSubmit()
          .then((res)=>{
            this.$emit('success', { data: this.info });
          }).catch(()=>{
            this.error({ message: 'خاتالىق كۆرۈلدى، بەتنى يېڭىلاپ قايتا سىناڭ'});
            this.invalidBusy = false;
        })
      },
      handleUpdating(){
        this.invalidBusy = true;
      },
      handleUpdated(e){
        this.invalidBusy = false;
      },
    }
  }
</script>

<style scoped>

</style>
