<template>
  <div class="film-poster-form-container">
    <form-image-uploader
      id="background"
      :state="validateState('background')"
      label="تەشۋىقات رەسىم قېلىپى"
      :upload-success="onUploadSuccess"
      :image-url="formData.background"
      :preview-url="formData.preview"
      :upload-form="{strategy: 'poster'}"
      :width="750"
      :height="1334"
      :no-circle="true"
    />
    <b-row>
      <b-col md="4">
        <b-form-group id="input-name-position" :state="validateState('position', 'name')" aria-describedby="name-position-feedback" label=" فىلىم ئىسمى ئورنى" label-for="name-position" description="بىر قۇرغا بىر ئۇچۇر كىرگۈزۈڭ، ئۈستىدىكى قۇرغا x ئوقتىكى ئورنىنى، ئاستىدىكى قۇرغا y ئوقتىنى ئورنىنى كىرگۈزۈڭ"
        >
          <b-form-textarea max-rows="2" style="direction: ltr;" :state="validateState('position', 'name')" aria-describedby="name-position-feedback" v-model="formData.name.position.raw" :formatter="formatNamePosition" placeholder="پۈتۈن سانلىق قىممەت تولدۇرۇڭ" id="name-position" />
          <b-form-invalid-feedback id="name-position-feedback">
            فىلىم ئىسمى ئورنىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group id="input-name-size" number type="number" :state="validateState('size', 'name')" aria-describedby="name-size-feedback" label="فىلىم ئىسمى رازمېرى" label-for="name-size" description="فىلىم ئىسمى چوڭ كىچىكلىكىگە پۈتۈن سان قىممىتى تولدۇرۇڭ" >
          <b-form-input number  style="direction: ltr;" :state="validateState('size', 'name')" aria-describedby="name-size-feedback" v-model="formData.name.size" placeholder="پۈتۈن سانلىق قىممەت تولدۇرۇڭ" id="name-size" />
          <b-form-invalid-feedback id="name-size-feedback">
            فىلىم ئىسمى رازمېرىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group id="input-name-color" :state="validateState('color', 'name')" aria-describedby="name-color-feedback" label="فىلىم ئىسمى رەڭگى" label-for="name-color" description="فىلىم ئىسمى ئىشلىتىدىغان خەت رەڭ نۇمۇرىنى تولدۇرۇڭ"
        >
          <b-input-group append="#">
            <b-form-input :formatter="formatColor" :state="validateState('color', 'name')" aria-describedby="name-color-feedback" placeholder="رەڭ نۇمۇرى HEX قىممىتىنى تولدۇرۇڭ" style="direction: ltr" v-model="formData.name.color" />
            <b-form-invalid-feedback id="name-color-feedback">
              فىلىم ئىسمى رەڭ نۇمۇرىنى كىرگۈزۈڭ
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group id="input-cover_image-size" :state="validateState('size', 'cover_image')" aria-describedby="cover_image-size-feedback" label="ئالبوم رەسىم رازمېرى" label-for="cover_image-size" description="بىر قۇرغا بىر ئۇچۇر كىرگۈزۈڭ، ئۈستىدىكى قۇرغا كەڭلىكنى، ئاستىدىكى قۇرغا ئېگىزلىكنى كىرگۈزۈڭ"
        >
          <b-form-textarea max-rows="2" style="direction: ltr;" :state="validateState('size', 'cover_image')" aria-describedby="cover_image-size-feedback" v-model="formData.cover_image.size.raw" :formatter="formatCoverImageSize" placeholder="پۈتۈن سانلىق قىممەت تولدۇرۇڭ" id="cover_image-size" />
          <b-form-invalid-feedback id="cover_image-size-feedback">
            فىلىم ئالبوم رەسىم رازمېرىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group id="input-avatar-cover_image" :state="validateState('position', 'cover_image')" aria-describedby="cover_image-position-feedback" label="ئالبوم رەسىم ئورنى" label-for="cover_image-position" description="بىر قۇرغا بىر ئۇچۇر كىرگۈزۈڭ، ئۈستىدىكى قۇرغا x ئوقتىكى ئورنىنى، ئاستىدىكى قۇرغا y ئوقتىنى ئورنىنى كىرگۈزۈڭ"
        >
          <b-form-textarea max-rows="2" style="direction: ltr;" :state="validateState('position', 'cover_image')" aria-describedby="cover_image-position-feedback" v-model="formData.cover_image.position.raw" :formatter="formatCoverImagePosition" placeholder="پۈتۈن سانلىق قىممەت تولدۇرۇڭ" id="cover_image-position" />
          <b-form-invalid-feedback id="cover_image-position-feedback">
            ئالبوم رەسىم ئورنىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group id="input-cover_image-circle" label="ئالبوم رەسىم يۇمىلاقلىنامدۇ" label-for="cover_image-circle" >
          <b-form-radio-group id="cover_image-circle" v-model="formData.cover_image.circle" :options="[{text: 'شۇنداق', value: 1}, {text: 'ياق', value: 0}, ]" buttons />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group id="input-qrCode-size" :state="validateState('size', 'qrCode')" aria-describedby="qrCode-size-feedback" label="ئىككىلىك كود رازمېرى" label-for="qrCode-size" description="بىر قۇرغا بىر ئۇچۇر كىرگۈزۈڭ، ئۈستىدىكى قۇرغا كەڭلىكنى، ئاستىدىكى قۇرغا ئېگىزلىكنى كىرگۈزۈڭ"
        >
          <b-form-textarea max-rows="2" style="direction: ltr;" :state="validateState('size', 'qrCode')" aria-describedby="qrCode-size-feedback" v-model="formData.qrCode.size.raw" :formatter="formatQrCodeSize" placeholder="پۈتۈن سانلىق قىممەت تولدۇرۇڭ" id="qrCode-size" />
          <b-form-invalid-feedback id="qrCode-size-feedback">
            ئىككىلىك كود رازمېرىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group id="input-qrCode-position" :state="validateState('position', 'qrCode')" aria-describedby="qrCode-position-feedback" label="ئىككىلىك كود ئورنى" label-for="qrCode-position" description="بىر قۇرغا بىر ئۇچۇر كىرگۈزۈڭ، ئۈستىدىكى قۇرغا x ئوقتىكى ئورنىنى، ئاستىدىكى قۇرغا y ئوقتىنى ئورنىنى كىرگۈزۈڭ"
        >
          <b-form-textarea max-rows="2" style="direction: ltr;" :state="validateState('position', 'qrCode')" aria-describedby="qrCode-position-feedback" v-model="formData.qrCode.position.raw" :formatter="formatQrCodePosition" placeholder="پۈتۈن سانلىق قىممەت تولدۇرۇڭ" id="qrCode-position" />
          <b-form-invalid-feedback id="qrCode-position-feedback">
            ئىككىلىك كود ئورنىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col md="4" v-if="belongs === 'mp'">
        <b-form-group id="input-qrCode-style" label="ئىككىلىك كود شەكلى" label-for="qrCode-style" >
          <b-form-radio-group id="qrCode-style" v-model="formData.qrCode.style" :options="[{text: 'چاچما كود', value: 1}, {text: 'ئىككىلىك كود', value: 2}, ]" buttons />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import FormImageUploader from "../../partials/layout/FormImageUploader";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import formBusyMixin from "@m@//common/formBusy.mixin";
  import filmMixin from "@m@/film.mixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";

  export default {
    name: "FilmPosterForm",
    components: { FormImageUploader },
    mixins: [validationMixin, filmMixin, formBusyMixin, sweetAlertMixin],
    validations: {
      formData: {
        background: {
          required
        },
        cover_image: {
          size:     { required },
          position: { required }
        },
        qrCode: {
          size:     { required },
          position: { required }
        },
        name: {
          size:     { required },
          position: { required },
          color:    { required }
        }
      }
    },
    computed: {},
    props: {
      belongs: {
        type: String,
        default: ()=> 'mp'
      }
    },
    created() {
      this.getFilmPoster(this.belongs)
        .then(response=>{
          console.log(response);
          if ( response.data.hasOwnProperty('background') ){
            this.formData = response.data;
          }
        })
    },
    data(){
      return {
        formBusy: false,
        formData: {
          background: null,
          cover_image: {
            circle: 0,
            size: {
              width: null,
              height: null,
              raw: ''
            },
            position: {
              x: 0,
              y: 0,
              raw: ''
            }
          },
          name: {
            size: 20,
            position: {
              x: 0,
              y: 0,
              raw: ''
            },
            color: '#ffffff'
          },
          qrCode: {
            style: 1,
            size: {
              width: null,
              height: null,
              raw: ''
            },
            position: {
              x: 0,
              y: 0,
              raw: ''
            }
          }
        },
        suckerCanvas: null,
        suckerArea: [],
        isSucking: false
      };
    },
    methods: {
      validateState(name, parent = null) {
        if ( parent ){
          const { $dirty, $error } = this.$v.formData[parent][name];
          return $dirty ? !$error : null;
        }
        const { $dirty, $error } = this.$v.formData[name];
        return $dirty ? !$error : null;
      },
      onSubmit(e){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: 'تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ'});
        }
        this.formData.belongs = this.belongs;
        if( this.belongs === 'h5' ){
          this.formData.qrCode.style = 2;
        }
        this.updateFilmPoster(this.formData);
      },
      onUploadSuccess(response){
        this.formData.background = response.path;
        this.formData.preview = response.url;
      },
      formatCoverImageSize(e){
        let [width, height] = this.formatString(e);
        Object.assign(this.formData.cover_image.size, {width, height});
        return e;
      },
      formatCoverImagePosition(e){
        let [x, y] = this.formatString(e);
        Object.assign(this.formData.cover_image.position, {x, y});
        return e;
      },
      formatQrCodeSize(e){
        let [width, height] = this.formatString(e);
        Object.assign(this.formData.qrCode.size, {width, height});
        return e;
      },
      formatQrCodePosition(e){
        let [x, y] = this.formatString(e);
        Object.assign(this.formData.qrCode.position, {x, y});
        return e;
      },
      formatNamePosition(e){
        let [x, y] = this.formatString(e);
        Object.assign(this.formData.name.position, {x, y});
        return e;
      },
      formatString(str){
        if ( ! str ) return [];
        return str.split(/[(\r\n)\r\n]+/);
      },
      formatColor(e){
        if ( e.startsWith('#') ){
          return e.toUpperCase();
        }else {
          return '#' + e.toUpperCase();
        }
      },
    }
  }
</script>
