<template>
  <b-overlay :show="filmBusy">
    <portlet title="سالون تەشۋىقات تەڭشىكى">
      <template v-slot:body>
        <film-poster-form
           ref="poster"
           belongs="h5"
           @loading="onLoading"
           @loaded="onLoaded"
           @updating="onUpdating"
           @updated="onUpdated"
        />
      </template>
      <template v-slot:foot>
        <b-button id="submit" @click="handleSubmit" variant="primary">
          ساقلاش
        </b-button>
        <b-button class="ml-2" variant="danger">
          بىكار قىلىش
        </b-button>
      </template>
    </portlet>
  </b-overlay>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import FilmPosterForm from "@v@/components/poster/FilmPosterForm";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import { debugConsole } from "@c@/helpers/helpers";
  export default {
    name: "index",
    components: { Portlet, FilmPosterForm },
    mixins: [ formBusyMixin, sweetAlertMixin ],
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'فىلىم باشقۇرۇش', route: {name: 'film.home'}});
      BreadCrumbs.addBreadCrumb({title: 'سالون تەشۋىقات تەڭشىكى'});
      if ( this.$refs.hasOwnProperty('poster') ){
        this.form = this.$refs['poster'];
      }
    },
    data(){
      return {
        filmBusy: false,
        form: {}
      };
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onUpdating(){
        this.showBusy('filmBusy');
        debugConsole({prefix: 'film', message: `onStored`});
      },
      onUpdated(state){
        this.hideBusy('filmBusy');
        debugConsole({prefix: 'film', message: `onStored: ${state}`});
        this.success({message: 'تەشۋىقات تەڭشىكى يېڭىلاندى'});
      },
      onLoading(){
        this.showBusy('filmBusy');
      },
      onLoaded(){
        this.hideBusy('filmBusy');
      },
    }
  }
</script>

<style scoped>

</style>
